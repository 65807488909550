var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detail"},[_c('el-container',{style:({ height: _vm.Height + 'px' })},[_c('el-main',{staticStyle:{"background":"white","margin":"20px"}},[_c('div',{staticClass:"main_box"},[(_vm.articleDetail._source)?_c('div',{staticClass:"bread"},[(_vm.articleDetail._source)?_c('el-breadcrumb',{attrs:{"separator-class":"el-icon-arrow-right"}},[(_vm.$route.query.webPage == '1')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("中文期刊推荐")])]):_vm._e(),(_vm.$route.query.webPage == '2')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("英文期刊推荐")])]):_vm._e(),(_vm.$route.query.webPage == '3')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("个人中心")])]):_vm._e(),(_vm.$route.query.webPage == '4')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  webPage: _vm.$route.query.webPage,
                },
              }}},[_c('span',[_vm._v("机构订阅")])]):_vm._e(),(_vm.$route.query.journal && _vm.$route.query.webPage !== '4')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/journalsList',
                query: {
                  subject: _vm.$route.query.type,
                  language: _vm.articleDetail._source.language,
                  webPage: _vm.$route.query.webPage
                },
              }}},[_vm._v(_vm._s(_vm.$route.query.type)+"期刊")]):_vm._e(),(_vm.$route.query.journal && _vm.$route.query.webPage == '4')?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/journalsList',
                query: {
                  subject: _vm.$route.query.type,
                  language: 2,
                  webPage: _vm.$route.query.webPage
                },
              }}},[_vm._v(_vm._s(_vm.$route.query.type)+"期刊")]):_vm._e(),(_vm.$route.query.journal)?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/journalsDetail',
                query: {
                  id: _vm.$route.query.journal_id,
                  subject: _vm.$route.query.type,
                  webPage: _vm.$route.query.webPage
                },
              }}},[_vm._v(_vm._s(_vm.$route.query.journal))]):_vm._e(),(_vm.$route.query.search)?_c('el-breadcrumb-item',{attrs:{"to":{
                path: '/academic',
                query: {
                  search: _vm.$route.query.search,
                  type: _vm.$route.query.type,
                  webPage: _vm.$route.query.webPage
                },
              }}},[_vm._v("期刊检索：“"+_vm._s(_vm.$route.query.search)+"”")]):_vm._e(),(_vm.$route.query.title)?_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$route.query.title))]):_vm._e(),(_vm.$route.query.period)?_c('el-breadcrumb-item',[_vm._v(_vm._s(_vm.$route.query.period))]):_vm._e()],1):_vm._e()],1):_vm._e(),(_vm.articleDetail._source)?_c('div',{staticClass:"articleList"},[_c('div',{staticClass:"article_list"},[_c('div',{staticClass:"title"},[_c('div',{staticClass:"titleTop"},[(_vm.articleDetail._source.language == '0')?_c('h2',[_vm._v(" "+_vm._s(_vm.articleDetail._source.ch_title)+" ")]):_vm._e(),(_vm.articleDetail._source.language == '1')?_c('h2',[_vm._v(" "+_vm._s(_vm.articleDetail._source.title)+" ")]):_vm._e(),(
                    _vm.articleDetail._source.language == '1' &&
                    _vm.articleDetail._source.ch_title !== ''
                  )?_c('h3',{staticStyle:{"margin":"0","text-align":"center"}},[_vm._v(" "+_vm._s(_vm.articleDetail._source.ch_title)+" ")]):_vm._e()]),_c('div',{staticClass:"but"},[(
                    _vm.$route.query.ispdf == 0 &&
                    !_vm.articleDetail.typeFull &&
                    _vm.articleDetail._source.pdf_link == '' &&
                    _vm.articleDetail._source.personalized !== 1
                  )?_c('el-button',{staticStyle:{"margin":"0 20px 0 0"},attrs:{"size":"mini","round":""},on:{"click":function($event){return _vm.fullText(_vm.articleDetail)}}},[_vm._v("获取全文")]):_vm._e(),(
                    _vm.$route.query.ispdf == 0 &&
                    !_vm.articleDetail.typeFull &&
                    _vm.articleDetail._source.pdf_link == '' &&
                    _vm.articleDetail._source.personalized == 1
                  )?_c('el-button',{staticStyle:{"margin":"0 20px 0 0"},attrs:{"size":"mini","round":""},on:{"click":function($event){return _vm.orinagal01(_vm.articleDetail)}}},[_vm._v("原文传递")]):_vm._e(),(_vm.$route.query.ispdf == 1)?_c('el-button',{staticStyle:{"margin":"0 20px 0 0","background":"#b14a75","color":"white"},attrs:{"size":"mini","round":""}},[_vm._v("已传递")]):_vm._e(),(
                    _vm.$route.query.ispdf == 0 &&
                    !_vm.articleDetail.typeFull &&
                    _vm.articleDetail._source.pdf_link !== ''
                  )?_c('el-button',{staticStyle:{"margin":"0 20px 0 0"},attrs:{"size":"mini","round":""},on:{"click":function($event){return _vm.down_link(_vm.articleDetail)}}},[_vm._v("下载链接")]):_vm._e(),(
                    _vm.articleDetail.typeFull &&
                    _vm.articleDetail.typeFull == '需求提交成功'
                  )?_c('el-button',{staticStyle:{"margin":"0 20px 0 0"},attrs:{"size":"mini","round":"","type":"success"}},[_vm._v("已提交")]):_vm._e(),(
                    _vm.articleDetail.typeFull &&
                    _vm.articleDetail.typeFull !== '需求提交成功'
                  )?_c('el-button',{staticStyle:{"margin":"0 20px 0 0"},attrs:{"size":"mini","round":"","type":"danger"}},[_vm._v(_vm._s(_vm.articleDetail.typeFull))]):_vm._e(),(_vm.articleDetail._source.isCollect == 0)?_c('img',{attrs:{"src":require("../../../assets/imgs/icons/collect.png"),"alt":""},on:{"click":function($event){return _vm.collection(_vm.articleDetail)}}}):_vm._e(),_c('el-popconfirm',{attrs:{"title":"确认取消收藏吗？"},on:{"confirm":function($event){return _vm.collection(_vm.articleDetail)}}},[(_vm.articleDetail._source.isCollect == 1)?_c('img',{attrs:{"slot":"reference","src":require("../../../assets/imgs/icons/collect1.png"),"alt":""},slot:"reference"}):_vm._e()])],1)]),_c('div',{staticClass:"article_essay"},[(_vm.authors !== '')?_c('p',[_c('span',{staticClass:"tit_article"},[_vm._v("作者：")]),_c('span',[_vm._v(" "+_vm._s(_vm.authors)+" ")])]):_vm._e(),(
                  _vm.articleDetail._source.abstract.length > 0 &&
                  _vm.articleDetail._source.language == '1'
                )?_c('p',[_c('span',{staticClass:"tit_article"},[_vm._v("摘要：")]),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(_vm._s(_vm.articleDetail._source.abstract))])]):_vm._e(),(
                  _vm.articleDetail._source.ch_abstract.length > 0 &&
                  _vm.articleDetail._source.language == '0'
                )?_c('p',[_c('span',{staticClass:"tit_article",staticStyle:{"text-align":"justify"}},[_vm._v("摘要：")]),_c('span',[_vm._v(_vm._s(_vm.articleDetail._source.ch_abstract))])]):_vm._e(),(
                  _vm.articleDetail._source.ch_abstract.length > 0 &&
                  _vm.articleDetail._source.language == '1'
                )?_c('p',[_c('span',{staticClass:"tit_article"},[_vm._v("翻译：")]),_c('span',{staticStyle:{"text-align":"justify"}},[_vm._v(_vm._s(_vm.articleDetail._source.ch_abstract))])]):_vm._e(),(
                  _vm.articleDetail._source.keyword.length > 0 &&
                  _vm.articleDetail._source.language == '1'
                )?_c('p',[_c('span',{staticClass:"tit_article"},[_vm._v("关键词：")]),_c('span',[_vm._v(_vm._s(_vm.en_words))])]):_vm._e(),(
                  _vm.articleDetail._source.ch_keyword.length > 0 &&
                  _vm.articleDetail._source.language == '0'
                )?_c('p',[_c('span',{staticClass:"tit_article"},[_vm._v(" 关键词：")]),_c('span',[_vm._v(_vm._s(_vm.ch_words))])]):_vm._e(),(_vm.articleDetail._source.doi !== '')?_c('p',[_c('span',{staticClass:"tit_article"},[_vm._v("DOI：")]),_c('span',[_vm._v(" "+_vm._s(_vm.articleDetail._source.doi)+" ")])]):_vm._e()])])]):_vm._e()])])],1),_c('el-dialog',{attrs:{"title":"原文传递","visible":_vm.dialogVisible,"width":'500px'},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form}},[_c('el-form-item',{attrs:{"label":"邮箱确认","label-width":_vm.formLabelWidth,"prop":"email","rules":[
          { required: true, message: '请输入邮箱地址', trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur', 'change'],
          },
        ]}},[_c('el-input',{attrs:{"autocomplete":"off"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.orinagal('form')}}},[_vm._v("确 定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }